window.SignInForm = (function($) {
  const SignInForm = function(emailDomainRedirects) {
    this.emailDomainRedirects = emailDomainRedirects;
    this.$email = $('#id_login');
    this.$signin_form = $('.c-login__signform');
    this.$saml_login_redirect_form = $('#saml_login_redirect_form');
    this.$email.focus();
    this.$email.on('keyup', (e) => {
      this.check_email(e);
    });
    this.$signin_form.on('submit', (e) => {
      this.check_email(e);
    });
  };

  SignInForm.prototype = {

    check_email(e) {
      let email = this.$email.val();
      if (email !== undefined) {
        const emailDomain = email.split('@')[1];
        if (emailDomain in this.emailDomainRedirects) {
          if (e.type === 'submit') {
            e.preventDefault();
          }
          let redirectUrl = this.emailDomainRedirects[emailDomain];

          // POST to the saml_login endpoint, which will redirect the user to their IdP's login page.
          // Allauth refuses to perform this redirect on a GET request for security reasons
          // (documented at https://docs.allauth.org/en/dev/release-notes/2021.html).
          fetch(
            redirectUrl,
            {
              method: 'GET',
              credentials: 'include',
            },
          ).then(async (response) => {
            let html = $.parseHTML(await response.text());
            this.$saml_login_redirect_form.append($('input[type=hidden]', html));
            this.$saml_login_redirect_form.attr('action', redirectUrl);
            this.$saml_login_redirect_form.submit();
          });
        }
      }
    },

  };

  return SignInForm;
}(window.$));
